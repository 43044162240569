import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filename"]

  fileChanged(event) {
    const filename = event.target.files[0].name
    this.filenameTarget.innerHTML = filename
    this.element.classList.add("file-selected")
  }
}
