import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
	static values = {
		baseUrl: String
	}
	static targets = ["signedIdInput", "publicCheckbox"]

	uploadFile(event) {
		const file = event.target.files[0]
		const directUpload = new DirectUpload(file, this._directUploadURL)
		directUpload.create((error, blob) => {
			if (error) {
				console.log(error)
			} else {
				console.log(blob)
				this.signedIdInputTarget.value = blob.signed_id
			}
		})
	}

	get _directUploadURL() {
		return `${this.baseUrlValue}/${this.publicCheckboxTarget.checked ? "public" : "private"}`
	}
}
