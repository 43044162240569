import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
		clientData: Object
	}
	static targets = ["paymentElement"]

	initialize() {
		console.log("Init")
		if (this.clientDataValue.publishable_key) {
			this.stripe = Stripe(this.clientDataValue.publishable_key, {stripeAccount: this.clientDataValue.account_id})
			this.stripeElements = this.stripe.elements(this._elementsOptions)
		}
		// window.stripeController = this
	}

	connect() {
		console.log("Connect")
		if (this.stripeElements && this.hasPaymentElementTarget && !this.paymentElement) {
			this.paymentElement = this.stripeElements.create("payment")
			this.paymentElement.mount(this.paymentElementTarget)
			// window.paymentElement.on('change', function(event) {
			// 	console.log(event)
			// });
		}
	}

	get _elementsOptions() {
		return {
			amount: 500,
			currency: "usd",
			mode: "payment",
			loader: "always"
		}
	}

  // get _accountId() {
  //   return this.clientDataValue.account_id
  // }

  // get _publishableKey() {
  //   return this.clientDataValue.publishable_key
  // }

	// submit(event) {
	// 	event.preventDefault()
	// 	if (this.intentObjectSecretValue) {
	// 		if (this.intentObjectTypeValue == "setup") {
	// 			this._confirmSetup()
	// 		} else {
	// 			this._confirmPayment()
	// 		}
	// 	} else {
	// 		alert("We don't have an intent object client secret. Is this checkout finalized with Stripe?")
	// 	}
	// }

	// _confirmPayment() {
	// 	var elements = this.elements
	// 	this.stripe.confirmPayment({
	// 		elements,
	// 		confirmParams: this._confirmParams,
	// 		redirect: 'if_required'
	// 	}).then(function(result) {
	// 		alert(result.error ? result.error.message : "The payment has been captured!")
	// 	})
	// }

	// _confirmSetup() {
	// 	var elements = this.elements
	// 	this.stripe.confirmSetup({
	// 		elements,
	// 		confirmParams: this._confirmParams,
	// 		redirect: 'if_required'
	// 	}).then(function(result) {
	// 		alert(result.error ? result.error.message : "The payment has been captured for future use!")
	// 	})
	// }

	// get _confirmParams() {
	// 	return {return_url: window.location.href}
	// }
}
