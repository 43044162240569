import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    event.preventDefault()
    window.navigator.clipboard.writeText(this.sourceTarget.textContent)
    this._flashConfirmation()
  }

  _flashConfirmation() {
    this._toggleConfirmationClass(true)
    setTimeout(() => this._toggleConfirmationClass(false), 2000)
  }

  _toggleConfirmationClass(state) {
    this.element.classList.toggle("show-confirmation", state)
  }
}
