import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
		accountId: String,
		intentObjectSecret: String,
		intentObjectType: String,
		publishableKey: String
	}
	static targets = ["paymentElement"]

	initialize() {
		if (this.publishableKeyValue) {
			this.stripe = Stripe(this.publishableKeyValue, {stripeAccount: this.accountIdValue})
		}
	}

	connect() {
		if (this.stripe && this.hasPaymentElementTarget && this.intentObjectSecretValue) {
			this.elements = this.stripe.elements({clientSecret: this.intentObjectSecretValue})
			this.paymentElement = this.elements.create("payment")
			this.paymentElement.mount(this.paymentElementTarget)
		}
	}

	submit(event) {
		event.preventDefault()
		if (this.intentObjectSecretValue) {
			if (this.intentObjectTypeValue == "setup") {
				this._confirmSetup()
			} else {
				this._confirmPayment()
			}
		} else {
			alert("We don't have an intent object client secret. Is this checkout finalized with Stripe?")
		}
	}

	_confirmPayment() {
		var elements = this.elements
		this.stripe.confirmPayment({
			elements,
			confirmParams: this._confirmParams,
			redirect: 'if_required'
		}).then(function(result) {
			alert(result.error ? result.error.message : "The payment has been captured!")
		})
	}

	_confirmSetup() {
		var elements = this.elements
		this.stripe.confirmSetup({
			elements,
			confirmParams: this._confirmParams,
			redirect: 'if_required'
		}).then(function(result) {
			alert(result.error ? result.error.message : "The payment has been captured for future use!")
		})
	}

	get _confirmParams() {
		return {return_url: window.location.href}
	}
}
