import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
hljs.registerLanguage('json', json)

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["code"]

	connect() {
		hljs.highlightElement(this.codeTarget)
	}
}
