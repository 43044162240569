import { Controller } from "@hotwired/stimulus"
import strftime from "strftime"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"

export default class extends Controller {
  static values = { format: String, timestamp: String, ago: Boolean }

  connect() {
    TimeAgo.addLocale(en)
    this.timeAgo = new TimeAgo('en-US')
    this.element.innerHTML = this._formattedTime
  }

  get _date() {
    return new Date(this.timestampValue)
  }

  get _formattedTime() {
    if (this.agoValue) {
      return this.timeAgo.format(this._date)
    } else {
      return strftime(this.formatValue, this._date)
    }
  }
}
