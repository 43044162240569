import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {inputName: String, reverse: {type: Boolean, default: false}}

  connect() {
    this.boundToggle = this._toggle.bind(this);
    this._inputs.forEach(input => input.addEventListener("change", this.boundToggle))
    this._toggle()
  }

  disconnect() {
    this._inputs.forEach(input => input.removeEventListener("change", this.boundToggle))
  }

  _toggle() {
    this.element.classList.toggle("hidden", this._isHidden)
  }

  get _checkedValue() {
    // Try to find checkable inputs (radio and checkbox) first, then fall back to hidden input.
    let input = this._inputs.find(input => input.checked == true) || this._inputs[0]
    return input ? input.value : null
  }

  get _inputs() {
    return Array.from(document.querySelectorAll(`[name="${this.inputNameValue}"]`))
  }

  get _isHidden() {
    return this.reverseValue ? this._checkedValue == "true" : this._checkedValue == "false"
  }
}
