import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { params: Array, pattern: String, excludePattern: String }

  connect() {
    this.boundToggleActiveClasses = this._toggleActiveClasses.bind(this)
    document.addEventListener("turbo:load", this.boundToggleActiveClasses)
  }

  disconnect() {
  	document.removeEventListener("turbo:load", this.boundToggleActiveClasses)
  }

  _toggleActiveClasses() {
    this.element.classList.toggle('active-link', this._isActive)
    this.element.classList.toggle('exact-active-link', this._isExactActive)
  }

  get _isActive() {
    return this._isExactActive || this._regexMatches
  }

  get _isExactActive() {
    return (this.element.pathname == window.location.pathname) && this._paramsMatch
  }

  get _elementParams() {
    return new URLSearchParams(this.element.search)
  }

  get _paramsMatch() {
    if (!this.hasParamsValue) return true
    return this.paramsValue.every(param => {
      return this._elementParams.get(param) == this._windowParams.get(param)
    })
  }

  get _regexMatches() {
    if (window.location.pathname.search(this.excludePatternValue) > 0) return false
    return window.location.pathname.search(this.patternValue) > 0
  }

  get _windowParams() {
    return new URLSearchParams(window.location.search)
  }
}
