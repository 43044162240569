import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { visible: Boolean, default: false }
  static targets = ["hideButton", "showButton", "table"]

  toggle() {
    this.visibleValue = !this.visibleValue
    this.showButtonTarget.classList.toggle("hidden", this.visibleValue)
    this.hideButtonTarget.classList.toggle("hidden", !this.visibleValue)
    this.tableTarget.classList.toggle("hidden", !this.visibleValue)
  }
}
