import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.sidebarHidden = true
    this.sidebarElement = document.getElementById("sidebar")
    this.sidebarContentElement = document.getElementById("sidebar-content")
  }

  connect() {
  	this.boundDocumentClick = this._documentClick.bind(this)
  	document.addEventListener("click", this.boundDocumentClick)
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    this.sidebarHidden = !this.sidebarHidden
    this.sidebarElement.classList.toggle("hidden", this.sidebarHidden)
    this.sidebarElement.classList.toggle("block", !this.sidebarHidden)
  }

  _documentClick(event) {
    const target = event.target || event.srcElement
    if (!target) { return }
    if (this.sidebarContentElement.contains(target)) { return }
    if (!this.sidebarHidden) {
      this.toggle(event)
    }
  }
}
