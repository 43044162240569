import { Controller } from "@hotwired/stimulus"
import URI from "urijs"

export default class extends Controller {
  static targets = ["intervalRadio", "intervalLink", "monthDisplay", "yearDisplay"]

  connect() {
    this.toggle();
  }

  toggle() {
    this._toggleRadios()
    this._toggleDisplays()
    this._toggleIntervalLinks()
  }

  _toggleDisplays() {
    this.monthDisplayTargets.forEach(element => { element.classList.toggle("hidden", this._selectedInterval != "month") })
    this.yearDisplayTargets.forEach(element => { element.classList.toggle("hidden", this._selectedInterval != "year") })
  }

  _toggleIntervalLinks() {
    this.intervalLinkTargets.forEach(element => {
      let uri = new URI(element.href)
      uri.setQuery("interval", this._selectedInterval)
      element.href = uri.toString()
    })
  }

  _toggleRadios() {
    this.intervalRadioTargets.forEach(element => {
      element.parentElement.classList.toggle("bg-primary", element.checked)
      element.parentElement.classList.toggle("text-white", element.checked)
    })
  }

  get _selectedInterval() {
    let checkedRadio = this.intervalRadioTargets.find(element => element.checked)
    return checkedRadio.value
  }
}
