import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    publicKey: String,
    accessCode: String,
  };

  submit(event) {
    event.preventDefault();
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: this.publicKeyValue,
      accessCode: this.accessCodeValue,
      onSuccess: (transaction) => {
        alert("The payment has been captured!");
      },
    });
  }
}
